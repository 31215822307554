.Pagination {
    overflow: hidden;
    padding: 5px;
    ul {
        overflow: hidden;
        padding: 5px 0px; }
    li {
        display: inline-block;
        &:last-child {
            a {
                border-right: 1px solid $grey-light; } }
        a {
            font-weight: 800;
            padding: 7px 9px;
            border: 1px solid $grey-light;
            border-right: 0px;
            color: $black;
            display: block;
            &:hover {
                color: $gold; }
            &.Pagination--active {
                font-weight: bold;
                color: $white;
                background: $gold; } } } }
