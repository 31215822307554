.align-left {
    text-align: left; }
.align-center {
    text-align: center; }
.align-right {
    text-align: right; }
.align-justify {
    text-align: justify; }

.is-white {
    background: $white; }
.is-brown {
    background: $brown; }
.is-brown-lightest {
    background: $brown-lightest; }
.is-brown-light {
    background: $brown-lightest; }
.is-red {
    background: $red; }
.is-transparent {
	background: none; }
