/* FONT PATH
 * -------------------------- */

$font-title: "Open Sans", sans-serif;
$font-p: "Open Sans", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;

h1, h2, h3, h4, h5 {
  font-family: $font-title;
  font-weight: 800; }

p, ul, ol, li {
  font-family: $font-p;
  font-weight: 400;
  b, strong {
    font-weight: 600; } }

p {
  color: $black-light;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 17px; }

i, italic {
	font-style: italic; }

li {
  color: $black-light;
  line-height: 24px;
  font-size: 16px; }

.content h2, .content h1.title, h1, h2 {
		padding: 5px 0px;
		margin-bottom: 0px;
		font-size: 27px;
		padding-bottom: 10px;
		margin-bottom: 10px; }

.content h2.noborder, h2.noborder {
	border-bottom: 0px; }

h3, .content h3 {
	font-size: 30px;
	padding-bottom: 10px;
	margin-bottom: 10px; }

a {
	text-decoration: none; }

b {
	font-weight: 800; }

a {
	color: $black;
	font-weight: bold;
	&:hover {
		color: $grey; } }
