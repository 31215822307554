* {
    transition: .8;
    -webkit-transition: .8;
    -moz-transition: .8; }

body {
    background: $white; }

.bigContainer {
    width: 1320px;
    margin: 0px auto; }

.container {
    width: 1240px;
    &.firstContainer {
        margin-top: -100px;
        background: $white;
        padding: 15px; } }

header {
    background: rgba($blue-light, .84);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 50; }

.Header {
    z-index: 15;
    .Logo {
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 10px; }
    .Claim {
        h2 {
            color: $white;
            font-size: 44px;
            padding: 0px;
            margin: 0px;
            margin-top: -20px;
            border-bottom: 0px; }
        h4 {
            padding-top: 50px;
            font-size: 34px; } }
    .Social {
        font-size: 16px;
        text-align: right;
        padding-right: 0px;
        display: block;
        a {
            color: $white;
            &:hover {
                color: $black; } } }
    .Social-area {
        text-align: right;
        padding-top: 20px; }
    .ApplyNowTop {
        padding-top: 20px;
        float: left;
        padding-left: 10px;
        a {
            padding: 5px;
            background: $brown;
            color: $white;
            &:hover {
                background: $blue;
                color: $black; } } } }
.notification {
    border-radius: 0px; }

.Banner_area {
    padding-top: 40px; }
.banner-footer {
    width: 100%;
    padding-left: 10px;
    padding-right: 20px; }

.Slider {
    background: url(../../image/slide1.jpg) top center no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding-top: 180px;
    &.Slider--big {
        height: 650px; }
    &.Slider--small {
        height: 380px; } }

.joinExperience {
    padding-top: 60px;
    text-align: right;
    padding-right: 20%;
    h4 {
        font-size: 28px;
        font-weight: 400;
        font-style: italic;
        font-weight: bold;
        padding-bottom: 0px;
        margin-bottom: 0px;
        text-align: right; }
    h2 {
        font-size: 45px;
        font-weight: 800;
        line-height: 50px;
        margin-bottom: 0px;
        padding-top: 0px;
        text-align: right; }
    h4, h2 {
        color: $white;
        text-shadow: 2px 5px 5px rgba($black, .6); }
    .ApplyNowSlide {
        padding-top: 0px;
        font-size: 22px;
        text-align: right;
        display: inline-block;
        margin-bottom: 5px;
        margin-left: 5px;
        a {
            padding: 5px 10px;
            background: $blue-light;
            color: $white;
            text-transform: uppercase;
            &:hover {
                background: $white;
                color: $grey-dark; } }
        &.ApplyNowSlideRed {
            a {
                padding: 5px 10px;
                background: $red;
                color: $white;
                text-transform: uppercase;
                &:hover {
                    background: $white;
                    color: $grey-dark; } } } } }

.Footer {
    background: $brown;
    padding-top: 20px;
    padding-bottom: 20px;
    p, ul, ol {
        font-size: 14px;
        color: $white; }
    h4 {
        color: $white;
        font-size: 14px; }
    h3 {
        color: $white;
        font-size: 25px;
        font-weight: 400;
        font-style: italic; }
    a {
        color: $white;
        &:hover {
            color: $blue; } }
    li {
        margin: 0px;
        font-size: 14px;
        a {
            color: $white;
            font-weight: normal; }
        &.active a, a:hover {
            color: $blue; } }
    .bigContainer {
        background: url(../../image/logofooter.png) bottom right no-repeat;
        background-size: 150px; }
    .notification {
        background: none; }
    .Footer__courses {
        padding-top: 25px;
        a {
            color: $blue-light;
            &:hover {
                color: $white; } } }
    .Footer__submenu {
        padding-top: 25px; }
    .Footer__contacts {
        a {
            color: $blue-light;
            &:hover {
                color: $white; } } }
    .Social {
        font-size: 30px;
        padding-top: 25px; } }

#Main {
    padding: 20px;
    margin-top: -60px;
    background: $white;
    position: relative;
    z-index: 10;
    &.mainContainer {
        margin-top: 0px;
        background: $white;
        .bigContainer {
            margin-top: -60px;
            background: $white;
            padding: 30px;
            z-index: 10; } } }


.Slider__content {
    padding: 10px; }

.content .corsiMenu {
    padding: 0px;
    margin: 0px;
    li {
        list-style: none;
        margin-bottom: 5px;
        a {
            display: block;
            padding: 4px;
            background: $white;
            color: $grey-med;
            border-bottom: 1px solid $brown-lightest; }
        &.active a, a:hover {
            background: $blue-light;
            color: $white; } } }

.columns.Corso__small_container {
    overflow: hidden;
    .Corso__small_content {
        width: 33%;
        float: left;
        padding: 5px;
        .Corso__small {
            padding: 0px;
            margin: 0px;
            text-align: center;
            a {
                .is-red {
                    background: $red;
                    color: $white; }
                &:hover .is-red {
                    background: $red-dark;
                    color: $white; }
                .is-brown {
                    background: $white;
                    color: $black; }
                &:hover .is-brown {
                    background: $blue-light;
                    color: $black; }
                .notification {
                    margin-top: -5px;
                    font-size: 14px;
                    height: 70px;
                    padding: 10px;
                    text-transform: uppercase; } } } } }

.columns.Area__big_container {
    overflow: hidden;
    .Area__big_content {
        width: 33%;
        float: left;
        padding: 5px;
        .Area__big {
            padding: 0px;
            margin: 0px;
            text-align: center;
            position: relative;
            a {
                transition: 1.0s;
                .is-red {
                    background: $red;
                    color: $white; }
                &:hover .is-red {
                    background: $red-dark;
                    color: $white; }
                .is-brown {
                    background: $white;
                    color: $black; }
                &:hover .is-brown {
                    background: $blue-light;
                    color: $black; }
                .notification {
                    margin-top: -5px;
                    font-size: 14px;
                    height: 70px;
                    padding: 10px;
                    text-transform: uppercase; }
                img {
                    transition: 1.0s; }
                .Area__title {
                    z-index: 10;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                    text-transform: uppercase;
                    font-size: 32px;
                    line-height: 38px;
                    color: $white;
                    text-shadow: 0px 4px 4px $black;
                    left: 0px;
                    top: 50%;
                    margin-top: -25px;
                    transition: 1.0s; }
                .Area__body {
                    z-index: 10;
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    margin-top: -30px;
                    text-shadow: 0px 4px 4px $black;
                    font-size: 19px;
                    line-height: 29px;
                    color: $white;
                    font-weight: bold;
                    width: 100%;
                    transition: 1.0s;
                    p, p strong, p b, p i {
                        color: $white; } }
                &:hover {
                    img {
                        -webkit-filter: brightness(50%) grayscale(30%);
                        filter: brightness(50%) grayscale(30%); }
                    .Area__body {
                        opacity: 1;
                        margin-top: 5px; }
                    .Area__title {
                        margin-top: -100px; } } } } } }


.iscrivitiSubito {
    a {
        background: $brown;
        padding: 20px;
        display: block;
        h3 {
            text-align: center;
            font-size: 35px;
            color: $white;
            font-style: italic;
            font-weight: -400;
            padding: 0px;
            margin: 0px; }
        &:hover {
            background: $blue; } }
    &.iscrivitySubito--blue {
        a {
            background: $blue-light;
            &:hover {
                background: $blue; } } } }

.lastFromBlog {
    background: $blue-light;
    padding: 20px 0px;
    .content {
        p {
            color: $white; }
        h3 {
            color: $brown;
            padding-top: 0px;
            margin-top: 0px; }
        a {
            color: $brown;
            &:hover {
                color:  $blue; } } }
    .listaPosts {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px dotted $blue; } }

.cosaBigogno {
    background: url(../../image/davide.png) bottom right no-repeat $grey-bg;
    background-size: 600px;
    padding: 20px 0px 50px 0px;
    h3 {
        padding: 10px 0px 20px 0px;
        margin-bottom: 0px;
        font-style: italic; }
    h4 {
        padding-bottom: 0px;
        margin-bottom: 0px;
        a {
            color: $brown;
            &:hover {
                color: $blue-light; } } } }

.content .Category, .Category {
    list-style: none;
    padding: 0px;
    margin: 0px;
    li {
        margin-bottom: 5px;
        a {
            display: block;
            padding: 5px;
            background: $grey-light; }
        &.active a, a:hover {
            color: $blue;
            background: $brown; } } }

.map-area {
    height: 309px;
    background: url(../../image/map.png) top center no-repeat;
    p {
        font-size: 12px;
        color: $white;
        padding-left: 10px; } }

fieldset {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid $grey-light;
    legend {
        font-size: 27px;
        font-family: $font-title;
        padding-left: 5px;
        padding-right: 5px; }
    fieldset {
        margin: 5px; } }

.elementAccodionMain {
    border-bottom: 1px solid $grey-light;
    &.firstItem {
        border-top: 1px solid $grey-light; } }


.accordion {
    border: 1px solid $grey-light;
    h3 {
        margin-bottom: 0px; }
    &.unactive {
        overflow: hidden;
        display: none;
        &.accordionLast {
            height: 0px; } }
    .elementAccodion {
        padding: 10px;
        font-size: 17px;
        cursor: pointer;
        color: $blue;
        &:hover, &.active {
            color: $black;
            background: $grey-lightest; } }

    &.active {
        display: block; }
    fieldset {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 0px;
        margin: 10px 0px;
        legend {
            font-size: 27px;
            font-family: $font-title;
            padding-left: 5px;
            padding-right: 5px; }
        fieldset {
            margin: 5px; } } }

.hidden, .alloggioNascosto, .cartaNascosto, .paypalNascosto, .bonificoNascosto, .transfertNascosto {
    display: none; }

.content h3.costo_totale {
    font-size: 40px; }

.content p.errore {
    padding: 3px;
    background: $red;
    color: $white; }

.bx-wrapper .bx-loading {
    display: none; }

.content h2:not(:first-child) {
    margin-top: 5px; }

.Documents {
    padding: 10px 0px;
    margin-top: 10px;
    border-top: 1px dotted $grey-light;
    a i {
        color: $red; }
    a:hover i {
        color: $grey; } }

.ApplyNow {
    position: absolute;
    bottom: 0px;
    left: 120px;
    width: 131px;
    height: 68px;
    a {
        display: block;
        height: 68px;
        background: url(../../image/applynow.png) top center no-repeat;
        &:hover {
            background: url(../../image/applynow.png) bottom center no-repeat; } } }

.notification {
    background: $white; }

ul.tabs {
    display: block;
    margin: 0px;
    padding: 0px;
    li {
        display: inline-block;
        border: 1px solid $grey-light;
        &.is-acive {
            border: 1px solid $blue-light;
            color: $blue;
            a {
                color: $blue;
                border-bottom: 1px solid $blue-light; } } } }

.tabs-content {
    margin-top: -15px;
    padding-top: 15px;
    border-top: 1px solid $grey-light; }
.tabs-panel {
    display: none;
    &.active-panel-active {
        display: block; } }
.tabs a {
    font-weight: normal; }

.tabs li.is-active a {
    color: $blue-light;
    border-bottom: 1px solid $blue-light; }

.richiedi-bottom {
    display: none; }

.CorsoDettaglio {
    p, h1, h2, h3, h4, h5, ul, li {
        color: $black; } }

.slicknav_menu {
    background: $white;
    .slicknav_btn {
        background: $white;
        .slicknav_menutxt {
            text-shadow: none;
            color: $black; }
        .slicknav_icon-bar {
            color: $black;
            background: $black;
            text-shadow: none;
            box-shadow: none; }
        a {
            color: $black; } }
    ul.slicknav_nav li, .slicknav_nav {
        .slicknav_row {
            color: $black; }
        .slicknav_row:hover {
            background: $white;
            color: $blue-light; }
        a.slicknav_item, a {
            color: $black; }
        a:hover {
            color: $blue-light;
            background: none; }
        * {
            font-weight: 600;
            text-transform: uppercase; }
        li.active {
            .slicknav_row {
                color: $blue-light; }
            .slicknav_row:hover {
                background: $white;
                color: $blue-light; }
            a.slicknav_item, a {
                color: $blue-light; }
            a:hover {
                color: $blue-light; } } } }

@keyframes shake3 {
    0% {
        left: -5px; }
    16% {
        left: 5px; }
    33% {
        left: -5px; }
    49% {
        left: 5px; }
    66% {
        left: -5px; }
    80% {
        left: 5px; }
    100% {
        left: 0; } }


input:invalid, textarea:invalid, select:invalid {
  border: 1px solid $grey; }

.richiedi-sx {
    padding-top: 50px; }

.ApplyNowCourse {
    margin: 10px 0px 20px 0px;
    a {
        padding: 5px 20px;
        background: $blue-light;
        color: $white;
        &:hover {
            background: $grey; } } }

.downloadPdf {
    margin: 5px 0px 10px 0px;
    a {
        padding: 5px 20px;
        background: $blue-light;
        color: $white;
        &:hover {
            background: $grey; } } }

.notification a:not(.button):not(.dropdown-item) {
    text-decoration: none; }

p.downloadPdf a {
    padding: 5px 10px;
    background: $blue-light;
    color: $white;
    text-transform: uppercase;
    &:hover {
        background: $white;
        color: $grey-dark; } }

.notification p.downloadPdf a:not(.button):not(.dropdown-item) {
    padding: 5px 10px;
    background: $blue-light;
    color: $white;
    text-transform: uppercase;
    &:hover {
        background: $blue;
        color: $grey-dark; } }

.zoomArea {
    padding-top: 10px;
    padding-bottom: 10px;
    h3 {
        margin-bottom: 0px; }
    p.zoomBtns {
        padding: 25px 0px 10px 0px;
        a {
            padding: 4px 10px;
            background: $blue;
            color: $white;
            margin-left: 15px;
            margin-right: 15px;
            &:hover {
                background: $grey;
                color: $white; } } } }

.newsletterArea {
    padding: 10px 0px 20px 0px;
    input, label {
        display: inline-block; }
    input {
        width: 250px;
        padding: 7px;
        border: 1px solid $grey; }
    input.button {
        background: $blue;
        width: auto;
        padding: 5px 20px;
        color: $white;
        margin-top: -1px;
        border: 0px; }
    &.blue {
        background: $blue-light;
        h2, label {
            color: $white; }
        input.button {
            background: $grey-dark; } } }

.PostCategory {
    padding: 10px 0px;
    text-align: center;
    width: 100%;
    line-height: 50px;
    li {
        display: inline-block;
        a {
            padding: 5px 10px;
            margin-left: 5px;
            margin-right: 5px;
            background: #F4F7F6;
            font-weight: normal;
            &:hover {
                background: #cccccc;
                color: $white; } }
        &.active {
            a {
                background: #cccccc;
                color: $white; } } } }

.couponArea {
    background: url(../../image/gift.png) top center no-repeat;
    text-align: center;
    .couponArea__price {
        font-size: 40px;
        font-weight: bold;
        color: #333;
        padding: 150px 30px 60px; }
    .couponArea__lesson {
        font-size: 20px;
        font-weight: bold; } }

.gift_button {
    background: $blue-light;
    display: inline-block;
    padding: 10px 30px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 0px;
    &:hover {
        background: $blue;
        color: #fff; } }


ol.gift_olList {
    padding-left: 0px;
    margin-left: 0px; }

.gift_olList {
    padding: 20px 0px 50px 0px;
    margin-left: 0px;
    li {
        display: block;
        max-width: 400px;
        text-align: center;
        margin: 0px auto;
        text-transform: uppercase;
        padding: 10px 0px;
        color: #999;
        .gift_olListNumber {
            font-weight: bold;
            color: #000; } } }

.corsiLista {
    display: none; }

.steps {
    padding: 10px 0px;
    margin-top: 10px;
    border-top: 1px dotted $grey-light;
    li.step-item {
        display: inline-block;
        width: calc(33% - 20px);
        padding: 5px;
        color: $grey;
        text-align: center;
        text-transform: uppercase;
        // background-color: $grey-light
        font-size: 16px;
        &.active {
            color: $blue;
 } } }            //background-color: $blue

.bottoniNext {
    text-align: center;
    .buttonNext {
        background: $blue-light;
        padding: 10px 20px;
        margin: 10px;
        font-size: 18px;
        color: $white;
        text-transform: uppercase;
        &:hover {
            background: $blue; } }
    .buttonPrev {
        background: $blue-light;
        padding: 10px 20px;
        margin: 10px;
        font-size: 18px;
        color: $white;
        text-transform: uppercase;
        &:hover {
            background: $blue; } } }
