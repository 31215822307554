input, textarea, select {
	width: 100%;
	padding: 6px;
	font-family: $font-p;
	font-size: 14px;
	background: $white;
	border: 1px solid $grey-light;
	color: $black;
	background: $grey-lightest; }

label {
	font-family: $font-p;
	color: $black;
	margin-bottom: 3px;
	font-size: 13px; }


input.btn {
	cursor: pointer;
	width: auto;
	padding: 5px 20px;
	color: $white;
	background: $blue;
	border: 0px;
	&:hover {
		background: $brown; }
	&.btn-full {
		background:  $black; } }

input.checkbox, input.alloggio, input[type='checkbox'], input[type='radio'] {
	width: auto; }
