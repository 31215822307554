#Menu {
	position: relative;
	z-index: 5;
	text-align: right;
	padding-top: 20px;
	li {
		display: inline-block;
		font-family: $font-title;
		//border-right: 1px solid $white
		margin-bottom: 0px;
		a {
			color: $black;
			font-size: 15px;
			padding: 3px 6px;
			font-weight: 600;
			text-transform: uppercase; }
		&.active a, a:hover {
			color: $white; }
		&:last-child {
			border-right: 0px solid $blue-light;
			a {
				padding-right: 0px; } }
		ul {
			text-align: left;
			width: 380px; }
		ul, &.active ul {
			display: none;
			background: $blue-light;
			position: absolute;
			top: 45px;
			box-shadow: 0px 0px 3px rgba($black, .7);
			li {
				display: block;
				border-right: 0px;
				position: relative;
				a {
					display: block;
					background: $blue-light;
					//color: $black
					//border-bottom: 1px solid $brown-light
					&:hover {
						color: $white; } }
				&.active a, a:hover {
					color: $white;
					background: $blue-light; }
				&:last-child {
					a {
						padding-right: 10px; } }
				&.menuCorsiNoCat {
					a {
						background: $blue-light; } }
				&.menuCorsiCatName {
					a {
						color: $black;
						background: $blue-light;
						&:hover {
							color: $white; } } }
				&.menuCorsiCat {
					a {
						color: $black;
						background: $blue-light;
						&:hover {
							color: $white; } }
					&.active a {
						color: $white; } } } }
		&:hover ul {
			display: block; }
		&:hover ul li ul {
			position: absolute;
			left: 380px;
			display: none;
			top: 0px; }
		&:hover ul li:hover ul {
			display: block; }
		a:hover {
			color: $white; }
		&.active li a {
			color: $black;
			&.active a, a:hover {
				color: $white; } }
		.menuCorsiCat.active a {
			color: $white; } } }

