.Language {
	padding: 20px 0px 0px 5px;
	display: inline-block;
	p {
		font-size: 12px;
		color: $white;
		padding-bottom: 5px;
		margin-bottom: 0px;
		text-transform: uppercase; }
	a {
		color: $white;
		padding: 2px 5px 2px 7px;
		//border-right: 1px solid $blue
		&.active, &:hover {
			color: $black; }
		&:last-child {
			border-right: 0px;
			padding-right: 0px; } } }
