.Breadcrumb {
    margin-top: 58px;
    padding: 5px 16px;
    background: url(../images/top.jpg) bottom left repeat-x;
    li.last {
        display: inline-block;
        padding-bottom: 4px;
        &:after {
            content: '/';
            padding: 0px 0px 0px 6px; }
        a {
            font-weight: normal;
            color: $black;
            &:hover {
                color: $gold; } }
        &:last-child {
            a {
                font-weight: 800; }
            &:after {
                content: ""; } } } }
