.slicknav_menu {
	display: none; }

.subCourser, .subCourser .corsiMenu {
	display: none; }

.mobileFooter-off {
	display: none; }

.mobileFooter-on {
	display: block; }

@include mobile {
	.gift_button {
		font-size: 16px; }
	.couponArea .couponArea__price {
        font-size: 35px; }
	.columns {
		margin-right: 0px; }
	.Slider .Slider__cloud, #MenuTop, #Menu {
    	display: none; }
	.slicknav_menu {
		display: block;
		z-index: 100;
		position: relative; }
	.columns.Corso__small_container {
		.Corso__small_content {
			width: 50%; } }
	.richiedi-bottom {
    	display: block; }
	.richiedi-sx {
    	display: none; }
	.bigContainer, .container {
		width: 100%; }
	header {
		top: 53px;
		z-index: 60; }
	.joinExperience {
		display: block;
		z-index: 100;
		position: relative;
		padding: 20px;
		padding-top: 70px;
		h2 {
			font-size: 35px;
			line-height: 40px; }
		h4 {
			margin-top: 5px;
			font-size: 25px;
			line-height: 30px; } }
	.Slider.Slider--big {
		height: 680px; }
	.column {
		padding: 15px; }
	.Header .Social {
		display: inline-block;
		font-size: 30px; }
	.Header .Language {
		display: inline-block;
		padding-right: 30px;
		font-size: 14px;
		vertical-align: super; }
	.Logo {
		padding-bottom: 0px;
		max-width: 300px; }
	.Header .Logo {
		margin-top: 20px; }
	.Header .column {
		padding-top: 0px;
		padding-bottom: 0px; }
	.Header .Social-area {
		padding-top: 0px;
		padding-bottom: 5px;
		text-align: center; }
	.Slider.Slider--small {
    	height: 450px; }
	.tabs a {
		font-size: 13px;
		padding-left: 3px;
		padding-right: 3px; }
	.CorsoDettaglio {
		padding-left: 0px;
		padding-right: 0px; }
	.content .PrezziTable table td {
		font-size: 13px;
		white-space: nowrap; }
	.tabs-panel.active-panel-active {
        overflow-x: auto; }
	.subCourser, .subCourser .corsiMenu {
		display: block; }
	.corsiMenu {
		display: none; }
	.joinExperience .ApplyNowSlide {
		font-size: 17px;
		margin-bottom: 12px; }
	.columns.Area__big_container .Area__big_content {
		width: 100%;
		.Area__title {
			font-size: 24px;
			line-height: 34px; } }
	.ApplyNowSlide {
		display: block;
		margin-bottom: 12px; }
	.ApplyNowCourse a {
		padding: 0px; }
	.mobileFooter-off {
		display: block; }
	.mobileFooter-on {
		display: none; }
	.Footer__courses {
		padding-top: 0px;
		padding-left: 20px;
		margin-top: -20px; }
	.noneMobile {
		display: none; }
	.steps ul {
		padding: 0px;
		margin: 0px; }
	.steps li.step-item {
		font-size: 12px;
		line-height: 14px;
		width: 32%;
		vertical-align: middle; } }

@include tablet-only {
	.Slider .Slider__cloud {
    	bottom: -30px; }
	#MenuTop, #Menu {
		display: none; }
	header {
		top: 53px;
		z-index: 60; }
	.slicknav_menu {
		display: block;
		z-index: 100;
		position: relative; }
	.columns.Corso__small_container {
		.Corso__small_content {
			width: 33%; } }
	.bigContainer, .container {
		width: 100%; }
	.joinExperience .ApplyNowSlide {
		font-size: 17px; } }


@media screen and (max-width: 1320) {
	.bigContainer, {
		width: 100%; } }

@media screen and (max-width: 1220) {
	.container, {
		width: 100%; } }
