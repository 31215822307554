$black: #222222;
$black-light: #666666;
$white: #ffffff;
$gold-dark: #a3988c;
$gold: #ccbeaa;
$grey: #d2d2d2;
$grey-med: #989898;
$grey-light: #e2e2e2;
$grey-lightest: #f5f5f5;
$grey-bg: #dadada;
$brown-lightest: #f4f2ed;
$brown-light: #BFA87C;
$brown: #38312c;
$blue: #2ba6cb;
$red: #be2627;
$red-dark: #821414;
$blue-light: #3ec2d6;
$blue: #0f94b4;
